<template>
  <div class="d-flex flex-column h-100 w-100">
    <div
      id="chatAnalyticsPage"
      class="d-flex flex-column flex-fill overflow-auto page-content"
    >
      <div class="filter-toolbar d-flex flex-wrap">
        <div v-if="onMultipleChatTeams" class="chat-filter">
          <MetricsFilter
            :filterData="teamFilterData"
            @submit-filter="handleTeamFilterSubmit"
            :icon="'filter-solid'"
            :iconText="'Filter'"
            :popoverId="'metrics-filter'"
            metricsType="chatAnalytics"
          />
        </div>
        <div v-else class="chat-filter">
          <h1 class="mb-0 h2 team-name">
            {{ teamName }}
          </h1>
        </div>
        <AnalyticsDatePicker analyticsType="chat" class="chat-filter" />
      </div>

      <div class="row no-gutters">
        <SimplePercentMetrics
          class="col-md"
          firstCard
          :title="totalConvo.title"
          :value="totalConvo.value"
          strongHeaderValue
          :description="totalConvo.description"
        ></SimplePercentMetrics>

        <SimplePercentMetrics
          class="col-md"
          :title="userAssisted.title"
          :value="userAssisted.value"
          strongHeaderValue
          :description="userAssisted.description"
        ></SimplePercentMetrics>

        <SimplePercentMetrics
          class="col-md"
          :lastCard="isMultiTeam"
          :title="botOnly.title"
          :value="botOnly.value"
          strongHeaderValue
          :description="botOnly.description"
        ></SimplePercentMetrics>

        <SimplePercentMetrics
          v-if="!isMultiTeam"
          lastCard
          class="col-md"
          :title="conversationRate.title"
          :value="conversationRate.value"
          strongHeaderValue
          :description="conversationRate.description"
          :conversationRate="true"
        ></SimplePercentMetrics>
      </div>

      <div class="row no-gutters mb-3 mt-3">
        <MetricsChart
          class="col-md"
          :title="'Conversations'"
          :series="chartSeries"
          :dates="startDateAndEndDate"
          :seriesFieldNames="seriesFieldNames"
          :seriesDisplayNames="seriesDisplayNames"
          :seriesColors="['#396FB0', '#737373']"
        />
      </div>

      <div class="row no-gutters mb-3">
        <div class="col-md pr-md-3">
          <div class="row no-gutters">
            <SimplePercentMetrics
              firstCard
              class="col-md"
              :value="requestedChatConversations.value"
              :percent="requestedChatConversationsPercentage.value"
              :description="requestedChatConversations.description"
            >
              <StackedBarChart
                :totalConvoValue="totalConvo.value"
                :requestedValue="requestedChatConversations.value"
                :seriesColors="['#243B4A', '#9BBCCA']"
              >
              </StackedBarChart>
            </SimplePercentMetrics>

            <SimplePercentMetrics
              lastCard
              class="col-md"
              :value="requestedFollowupConversations.value"
              :percent="requestedFollowupConversationsPercentage.value"
              :description="requestedFollowupConversations.description"
            >
              <StackedBarChart
                :totalConvoValue="totalConvo.value"
                :requestedValue="requestedFollowupConversations.value"
                :seriesColors="['#243B4A', '#9BBCCA']"
              >
              </StackedBarChart>
            </SimplePercentMetrics>
          </div>
        </div>
        <div class="col-md pl-md-3">
          <div class="row no-gutters h-50" :class="{ 'd-none': liveChatOnly }">
            <SimplePercentMetrics
              firstCard
              lastCard
              class="col-md"
              :title="selfServiceRate.title"
              :value="selfServiceRate.value"
              :percent="selfServiceRatePercentage.value"
              :description="selfServiceRate.description"
              horizontalLayout
            ></SimplePercentMetrics>
          </div>
          <div
            class="row no-gutters h-50"
            :class="{
              'd-flex flex-column h-100': liveChatOnly,
            }"
          >
            <SimplePercentMetrics
              firstCard
              :lastCard="liveChatOnly"
              :horizontalLayout="liveChatOnly"
              class="col-md"
              :class="{
                'text-center': !liveChatOnly,
              }"
              :title="leadCaptureRate.title"
              :value="leadCaptureRate.value"
              :percent="leadCaptureRatePercentage.value"
              :description="leadCaptureRate.description"
            ></SimplePercentMetrics>

            <SimplePercentMetrics
              :firstCard="liveChatOnly"
              lastCard
              :horizontalLayout="liveChatOnly"
              class="col-md"
              :class="{
                'text-center': !liveChatOnly,
              }"
              :title="mobileCaptureRate.title"
              :value="mobileCaptureRate.value"
              :percent="mobileCaptureRatePercentage.value"
              :description="mobileCaptureRate.description"
            ></SimplePercentMetrics>
          </div>
        </div>
      </div>

      <div class="row no-gutters w-100 heat-map-row">
        <MgCard
          mediumHeader="Conversations by Time of Day"
          description="Conversations summed by time of day on specific days of the week"
          class="w-100"
        >
          <div class="row no-gutters mt-3">
            <ChatAnalyticsHeatMapFilter
              class="col-md"
              :filterData="heatMapFilterData"
              :liveChatOnly="liveChatOnly"
              @submit-filter="handleHeatMapFilterSubmit"
            />
            <div class="d-none d-md-block col" />
            <ChatAnalyticsHeatMapLegend
              class="col-md legend"
              :min="heatMap.min"
              :max="heatMap.max"
            />
          </div>
          <div class="row no-gutters mt-3">
            <ChatAnalyticsHeatMap
              :days="heatMap.days"
              :min="heatMap.min"
              :max="heatMap.max"
              :filterData="heatMapFilterData"
              :liveChatOnly="liveChatOnly"
            />
          </div>
          <div class="d-md-none row no-gutters mt-3">
            <ChatAnalyticsHeatMapLegend
              class="col-md legend"
              :min="heatMap.min"
              :max="heatMap.max"
            />
          </div>
        </MgCard>
      </div>

      <div class="row no-gutters w-100 mt-3">
        <ChatAnalyticsBreakdown />
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsDatePicker from "@/pages/analytics/AnalyticsDatePicker";
import ChatAnalyticsBreakdown from "./ChatAnalyticsBreakdown";
import ChatAnalyticsHeatMap from "./ChatAnalyticsHeatMap";
import ChatAnalyticsHeatMapFilter from "./ChatAnalyticsHeatMapFilter";
import ChatAnalyticsHeatMapLegend from "./ChatAnalyticsHeatMapLegend";
import MetricsChart from "@/components/MetricsChart";
import MetricsFilter from "@/components/MetricsFilter";
import MgCard from "@/components/MgCard";
import SimplePercentMetrics from "@/components/SimplePercentMetrics";
import StackedBarChart from "@/components/StackedBarChart";
import _ from "lodash";
import analytics from "@/util/analytics";
import store from "@/store";
import {
  ACTIONS as CHAT_ACTIONS,
  MUTATIONS as CHAT_MUTATIONS,
} from "./chatAnalyticsStore";

export default {
  name: "ChatAnalyticsPage",
  store,
  components: {
    AnalyticsDatePicker,
    ChatAnalyticsBreakdown,
    ChatAnalyticsHeatMap,
    ChatAnalyticsHeatMapFilter,
    ChatAnalyticsHeatMapLegend,
    MetricsChart,
    MetricsFilter,
    MgCard,
    SimplePercentMetrics,
    StackedBarChart,
  },
  created() {
    this.$store.dispatch(CHAT_ACTIONS.GET_TEAM_ID);
    this.$store.dispatch(CHAT_ACTIONS.GET_TEAMS);
    this.$store.dispatch(CHAT_ACTIONS.GET_CURRENT_TEAM);
    this.$emit("updateTitle", "Chat");
    analytics.track("User Visited Chat Analytics Page");
  },
  destroyed() {
    this.$store.dispatch(CHAT_ACTIONS.CLEAR_REPORT);
  },
  watch: {
    async startDateAndEndDate(newValue) {
      if (!!newValue.startDate && !!newValue.endDate) {
        this.$store.dispatch(CHAT_ACTIONS.GET_ALL_DATA);
      }
    },
  },
  computed: {
    isMultiTeam() {
      return _.get(this.$store.state.webChat.bot, "isMultiTeam", false);
    },
    onMultipleChatTeams() {
      return this.$store.state.chatAnalytics.chatTeams.length > 1;
    },
    chatTeams() {
      return this.$store.state.chatAnalytics.chatTeams;
    },
    liveChatOnly() {
      return _.get(this.$store.state.webChat.bot, "type", false) === "LiveChat";
    },
    seriesFieldNames() {
      let fieldName1 = this.liveChatOnly ? "Unassisted" : "BotOnly";
      return ["UserAssisted", fieldName1];
    },
    seriesDisplayNames() {
      let displayName1 = this.liveChatOnly ? "Unassisted" : "Bot Only";
      return ["User Assisted", displayName1];
    },
    teamName() {
      return this.$store.state.chatAnalytics.currentTeamName;
    },
    teamId() {
      return this.$store.state.chatAnalytics.teamId;
    },
    heatMapConversationType() {
      return this.$store.state.chatAnalytics.heatMapConversationType;
    },
    heatMapFollowupRequested() {
      return this.$store.state.chatAnalytics.heatMapFollowupRequested;
    },
    teamFilterData() {
      return {
        teamId: this.teamId,
        currentTeams: this.chatTeams,
      };
    },
    heatMapFilterData() {
      return {
        heatMapConversationType: this.heatMapConversationType,
        heatMapFollowupRequested: this.heatMapFollowupRequested,
      };
    },
    startDateAndEndDate() {
      return this.$store.state.chatAnalytics.startDate
        ? {
            start: this.$store.state.chatAnalytics.startDate,
            end: this.$store.state.chatAnalytics.endDate,
            timeInterval: this.$store.state.chatAnalytics.timeInterval,
          }
        : {};
    },
    chartSeries() {
      return this.$store.state.chatAnalytics.series;
    },
    heatMap() {
      return this.$store.state.chatAnalytics.heatMap;
    },
    totalConvo() {
      return {
        title: "Total Conversations",
        value: this.findMetricValue("Conversations"),
        description:
          " Conversations where the site visitor sent at least one message to the chatbot",
      };
    },
    userAssisted() {
      return {
        title: "User-Assisted Conversations",
        value: this.findMetricValue("UserAssistedConversations"),
        description:
          "Conversations where the site visitor interacted with a live agent from the Cadence app",
      };
    },
    botOnly() {
      let title = this.liveChatOnly ? "Unassisted" : "Bot-Only";
      let description = this.liveChatOnly
        ? "Conversations where the site visitor did not interact with a live agent from the Cadence app"
        : "Conversations where the site visitor only interacted with the chatbot";
      return {
        title: `${title} Conversations`,
        value: this.findMetricValue("BotOnlyConversations"),
        description: description,
      };
    },
    conversationRate() {
      let metricValue = this.findMetricValue("ConversationRatePercentage");
      return {
        title: "Conversation Rate",
        value: `${Math.floor((metricValue || 0) * 100)}%`,
        description:
          "Percentage of the site visitors who opened the widget and sent at least one message to the chatbot",
      };
    },
    leadCaptureRate() {
      return {
        title: "Lead Capture Rate",
        value: this.findMetricValue("LeadCaptureNameAndEmail"),
        description:
          "Conversations where name and either email or mobile phone was captured",
      };
    },
    leadCaptureRatePercentage() {
      let metricValue = this.findMetricValue(
        "LeadCaptureNameAndEmailPercentage"
      );
      return {
        title: "Lead Capture Rate",
        value: Math.floor(metricValue * 100),
        description:
          "Conversations where name and either email or mobile phone was captured",
      };
    },
    mobileCaptureRate() {
      return {
        title: "Mobile Phone Capture Rate",
        value: this.findMetricValue("LeadCaptureMobilePhone"),
        description: "Conversations where mobile phone was captured",
      };
    },
    mobileCaptureRatePercentage() {
      let metricValue = this.findMetricValue(
        "LeadCaptureMobilePhonePercentage"
      );
      return {
        title: "",
        value: Math.floor(metricValue * 100),
        description: "Conversations where mobile phone was captured",
      };
    },
    selfServiceRate() {
      return {
        title: "Self-Service Rate",
        value: this.findMetricValue("SelfServiceRate"),
        description: `Conversations where the site visitor was satisfied with the chatbot answers`,
      };
    },
    selfServiceRatePercentage() {
      let metricValue = this.findMetricValue("SelfServiceRatePercentage");
      return {
        title: "",
        value: Math.floor(metricValue * 100),
        description:
          "Conversations where the site visitor was satisfied with the chatbot answers",
      };
    },
    requestedChatConversations() {
      return {
        title: "",
        value: this.findMetricValue("RequestedChatConversations"),
        description: "Conversations where live chat was requested",
      };
    },
    requestedChatConversationsPercentage() {
      let metricValue = this.findMetricValue(
        "RequestedChatConversationsPercentage"
      );
      return {
        title: "",
        value: Math.floor(metricValue * 100),
        description: "Conversations where live chat was requested",
      };
    },
    requestedFollowupConversations() {
      return {
        title: "",
        value: this.findMetricValue("RequestedFollowupConversations"),
        description: "Conversations where a follow-up was requested",
      };
    },
    requestedFollowupConversationsPercentage() {
      let metricValue = this.findMetricValue(
        "RequestedFollowupConversationsPercentage"
      );
      return {
        title: "",
        value: Math.floor(metricValue * 100),
        description: "Conversations where a follow-up was requested",
      };
    },
  },
  methods: {
    teamFilterType(filter) {
      if (filter.length === 1 && filter === this.teamFilterData.teamId) {
        return "Default Team";
      } else if (filter.length === 1 && filter !== this.teamFilterData.teamId) {
        return "Single Team";
      } else if (filter.length === this.teamFilterData.currentTeams.length) {
        return "All Teams";
      } else if (
        filter.length !== this.teamFilterData.currentTeams.length &&
        filter.length >= 2
      ) {
        return "2 or More Teams";
      }
    },
    handleTeamFilterSubmit(filter) {
      let filterType = this.teamFilterType(filter);
      sessionStorage.setItem(
        "chat-analytics-team-filter",
        JSON.stringify(filter)
      );
      this.$store.dispatch(CHAT_ACTIONS.GET_METRICS_FILTER, filter);
      analytics.track("Filtered Chat Analytics by Team", {
        "Filter Type": filterType,
        "Team Filter Count": filter.length,
      });
    },
    findMetricValue(metricName) {
      let metric = this.$store.state.chatAnalytics.metrics.find(
        (metric) => metric.name === metricName
      );

      return _.get(metric, "value");
    },
    handleHeatMapFilterSubmit(filter) {
      this.$store.commit(CHAT_MUTATIONS.SET_HEATMAP_FILTER, filter);
      this.$store.dispatch(CHAT_ACTIONS.GET_HEATMAP);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.page-content {
  padding-top: $grid-gutter-width/2;
}

.filter-toolbar {
  margin: 15px 0;
  @include media-breakpoint-down(md) {
    margin: 0px 0px 15px 0px;
  }
}

.heat-map-row {
  margin-top: 98px;

  @include media-breakpoint-up(md) {
    margin-top: unset;
  }
}

.legend {
  margin-top: 8px;

  @include media-breakpoint-up(md) {
    max-width: 15%;
    margin-top: unset;
  }
  margin-right: 13px;
}

.team-name {
  margin-right: 20px;
}

.chat-filter {
  @include media-breakpoint-up(md) {
    margin-right: 16px;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    margin-top: 15px;
    margin-right: 0px;
  }

  .dropdown-toggle {
    @include media-breakpoint-down(md) {
      display: flex;
      justify-self: flex-end;
    }
  }
}
</style>
