export const ACTIONS = {
  // Global
  GET_CAMPAIGNS: "a/getCampaigns",
  GET_CONFIG: "a/getConfig",
  GET_CONNECT_FILTERS: "a/getConnectFilters",
  GET_CONTACT: "a/getContact",
  GET_RADIUS_TARGETS: "a/getRadiusTargets",
  GET_USERS: "a/getUsers",
  LOAD_BULK_ACTIONS_SELECTED: "a/loadBulkActionsSelected",
  SHOW_CONTACT_CARD: "a/showContactCard",
  UPDATE_CAMPAIGN: "a/updateCampaign",
  UPDATE_CAMPAIGNS: "a/updateCampaigns",
  GET_SLATE_QUERIES: "a/getSlateQueries",
  GET_RE_NXT_LISTS: "a/getReNxtLists",

  // Inbox
  ARCHIVE_CONVERSATION: "a/inbox/archiveConversation",
  BIND_CONVERSATION: "a/inbox/bindConversation",
  BIND_CONVERSATIONS: "a/inbox/bindConversations",
  BIND_FIRESTORE_CONVERSATION: "a/inbox/bindFirestoreConversation",
  BIND_FIRESTORE_CONVERSATIONS: "a/inbox/bindFirestoreConversations",
  BIND_FIRESTORE_MESSAGES: "a/inbox/bindFirestoreMessages",
  BIND_MESSAGES: "a/inbox/bindMessages",
  BIND_NEXT_CONVERSATIONS: "a/inbox/bindNextConversations",
  BIND_NEXT_FIRESTORE_CONVERSATIONS: "a/inbox/bindNextFirestoreConversations",
  INCREMENT_EMPTY_INBOX_MESSAGES_INDEX:
    "a/inbox/incrementEmptyInboxMessagesIndex",
  LOAD_CONVERSATION_QUEUE: "a/inbox/loadConversationQueue",
  LOCK_CONVERSATION: "a/inbox/lockConversation",
  MARK_AS_READ_CONVERSATION: "a/inbox/markAsReadConversation",
  PAUSE_LIVE_INBOX: "a/inbox/pauseLiveInbox",
  RESET_CONVERSATION: "a/inbox/resetConversation",
  RESET_CONVERSATIONS: "a/inbox/resetConversations",
  RESET_FIRESTORE_CONVERSATION: "a/inbox/resetFirestoreConversation",
  RESET_FIRESTORE_CONVERSATIONS: "a/inbox/resetFirestoreConversations",
  RESUME_LIVE_INBOX: "a/inbox/resumeLiveInbox",
  SEARCH_FIRESTORE_CONVERSATIONS: "a/inbox/searchFirestoreConversations",
  SELECT_CONVERSATION: "a/inbox/selectConversation",
  SELECT_NEXT_CONVERSATION: "a/inbox/selectNextConversation",
  SHUFFLE_EMPTY_INBOX_MESSAGES: "a/inbox/shuffleEmptyInboxMessages",
  UNLOCK_CONVERSATION: "a/inbox/unlockConversation",
  UPDATE_ACTIVE_INBOX_FILTER: "a/inbox/updateActiveInboxFilter",
  UPDATE_ACTIVE_INBOX_SEARCH: "a/inbox/updateActiveInboxSearch",
  UPDATE_ACTIVE_INBOX_SORT: "a/inbox/updateActiveInboxSort",
  UPDATE_ACTIVE_INBOX: "a/inbox/updateActiveInbox",
  UPDATE_INBOX_FILTERS: "a/inbox/updateInboxFilters",
  UPDATE_INBOX_VIEW: "a/inbox/updateInboxView",
  UPDATE_SELECTED_CONVERSATION_MANUALLY_UNREAD:
    "a/inbox/updateSelectedConversationManuallyUnread",
  GET_INBOX_SENT: "a/inbox/getInboxSent",
  START_SIGNALR_INBOX_SENT: "a/inbox/startSignalRSent",

  // Scheduled Messages
  GET_SCHEDULED_MESSAGES_COUNTS: "a/scheduled/getCounts",
  GET_SCHEDULED_MESSAGES: "a/scheduled/getMessages",
  GET_SEGMENTS: "a/scheduled/getSegments",
  RESET_PAGINATION: "a/scheduled/resetPagination",
  SKIP_PAGE: "a/scheduled/skipPage",
  UNSCHEDULE_MESSAGE: "a/scheduled/unscheduleMessage",
  UPDATE_SCHEDULED_CALENDAR_MONTH: "a/scheduled/updateScheduledCalendarMonth",
  UPDATE_SCHEDULED_CALENDAR_YEAR: "a/scheduled/updateScheduledCalendarYear",
  UPDATE_SCHEDULED_DAY: "a/scheduled/updateScheduledDay",
  UPDATE_SCHEDULED_MONTH: "a/scheduled/updateScheduledMonth",
  UPDATE_SCHEDULED_YEAR: "a/scheduled/updateScheduledYear",
  UPDATE_SELECTED_CALENDAR_DAY: "a/scheduled/updateSelectedCalendarDay",
  UPDATE_SELECTED_DAY: "a/scheduled/updateSelectedDay",
  UPDATE_SELECTED_INBOX: "a/scheduled/updateSelectedInbox",
  FIND_FIRESTORE_CONVERSATION: "a/scheduled/findFirestoreConversation",

  // Automation
  GET_AUTOMATION_SETTINGS: "a/automation/getSettings",
  GET_MULTIPLE_AUTOMATION_SETTINGS: "a/automation/getMultipleSettings",
  UPDATE_AUTOMATION_SETTINGS: "a/automation/updateSettings",
  UPDATE_AUTOMATION_STATUS: "a/automation/updateStatus",

  // Contact History
  GET_CONTACT_HISTORY: "a/contact/getHistory",
  GET_MORE_CONTACT_HISTORY: "a/contact/getMoreHistory",
  RESET_CONTACT_HISTORY: "a/contact/resetHistory",

  // Tags
  GET_TAGS: "a/tag/getTags",
  UPDATE_TAG_DETAIL_FILTER: "a/tag/updateTagDetailFilter",
  UPDATE_TAG_LIST_FILTER: "a/tag/updateTagListFilter",
  UPDATE_TAG: "a/tag/updateTag",

  // Web Chat
  ASSIGN_WEBCHAT_CONVERSATION: "a/webChat/assignConversation",
  UNASSIGN_WEBCHAT_CONVERSATION: "a/webChat/unassignConversation",
  CLOSE_WEBCHAT_CONVERSATION: "a/webChat/closeConversation",
  REOPEN_WEBCHAT_CONVERSATION: "a/webChat/reopenConversation",
  GET_WEBCHAT_BOT: "a/webChat/getBot",
  GET_WEBCHAT_CONVERSATIONS: "a/webChat/getConversations",
  GET_OPEN_WEBCHAT_CONVERSATIONS: "a/webChat/getOpenConversations",
  START_SIGNALR_WEBCHAT_CONNECTION: "a/webChat/startSignalRConnection",
  STOP_SIGNALR_WEBCHAT_CONNECTION: "a/webChat/stopSignalRConnection",
  UPDATE_SELECTED_WEBCHAT_CONVERSATION_MESSAGE:
    "a/webchat/updateSelectedWebchatConversationMessage",
  DELETE_WEBCHAT_CONVERSATION_IN_LIST_BY_ID:
    "a/webChat/deleteWebchatConversationInListById",
  ADD_WEBCHAT_CONVERSATION_TO_LIST_BY_ID: "a/webChat/addConversationToListById",
  UPDATE_SELECTED_WEBCHAT_CONVERSATION: "a/webChat/updateSelectedConversation",
  UPDATE_UNREAD_WEBCHAT_CONVERSATIONS_COUNT:
    "a/webChat/updateUnreadConversationsCount",
  UPDATE_WEBCHAT_CONVERSATION_VISITOR_DETAILS: "a/webChat/updateVisitorDetails",
  UPDATE_WEBCHAT_CONVERSATION: "a/webChat/updateConversation",
  UPDATE_WEBCHAT_CONVERSATION_COLORS: "a/webChat/updateConversationColors",
  INCREMENT_WEBCHAT_VISITOR_AVATAR_COLOR_INDEX:
    "a/webChat/incrementVisitorAvatarColorIndex",
  START_WEBCHAT_HEARTBEAT: "a/webChat/startHeartbeat",
  UPDATE_WEBCHAT_FILTERS: "a/webChat/updateFilters",
  HANDLE_WEBCHAT_NOTIFICATION: "a/webChat/handleWebChatNotification",
  MARK_WEBCHAT_CONVERSATION_READ: "a/webChat/markConversationRead",
  SEND_WEBCHAT_SIGNALR_MESSAGE: "a/webChat/sendSignalRMessage",
  LOAD_MORE_WEBCHAT_OTHER_CONVERSATIONS: "a/webChat/loadMoreOtherConversations",
  LOAD_MORE_WEBCHAT_FOLLOWUP_CONVERSATIONS:
    "a/webChat/loadMoreFollowUpConversations",
  UPDATE_WEBCHAT_PAGINATION_META: "a/webChat/updatePaginationMeta",
  UPDATE_WEBCHAT_CONVERSATION_FOLLOWUP: "a/webChat/updateConversationFollowup",
  PULSE_WEBCHAT_TYPING_INDICATOR: "a/webChat/pulseTypingIndicator",
  CLEAR_WEBCHAT_INCOMING_TYPING_TIMER: "a/webChat/clearIncomingTypingTimer",
  CLEAR_WEBCHAT_OUTGOING_TYPING_TIMER: "a/webChat/clearOutgoingTypingTimer",
  GET_WEBCHAT_TEMPLATES: "a/webChat/getTemplates",

  //Analytics
  GET_METRICS: "a/analytics/getMetrics",
  GET_CURRENT_TEAMS: "a/analytics/getCurrentTeams",
  GET_CURRENT_INBOXES: "a/analytics/getCurrentInboxes",
  GET_ANALYTICS_BREAKDOWN: "a/analytics/getAnalyticsBreakdown",
  GET_TEAM_ID: "a/analytics/getTeamId",
  GET_ANALYTICS_DATES: "a/analytics/getAnalyticsDates",
  GET_ANALYTICS_FILTER: "a/analytics/getAnalyticsFilter",
  GET_INBOX_DETAILS_METRICS: "a/analytics/getInboxDetailsMetrics",
  GET_INBOX_DETAILS_BREAKDOWN: "a/analytics/getInboxDetailsBreakdown",
  GET_INBOX_DETAILS_DATES: "a/analytics/getInboxDetailsDates",
  GET_METRIC_EXPORT: "a/analytics/getMetricExport",
  GET_UNREAD_EXPORT: "a/analytics/getUnreadExport",
  CLEAR_METRICS: "a/analytics/clearMetrics",

  //Shared Inbox
  GET_SHARED_INBOXES: "a/sharedInbox/getSharedInboxes",
  GET_SHARED_INBOX_METRICS: "a/sharedInbox/getSharedInboxMetrics",
  GET_SHARED_INBOX_NUMBERS: "a/sharedInbox/getSharedInboxNumbers",
  GET_SHARED_INBOX_RESERVED_NUMBERS:
    "a/sharedInbox/getSharedInboxReservedNumbers",
  GET_SHARED_INBOX_STAFF: "a/sharedInbox/getSharedInboxStaff",
  GET_SHARED_INBOX_CONFIG: "a/sharedInbox/getSharedInboxConfig",
  GET_CONTRACT: "a/sharedInbox/getContract",
};

export const GETTERS = {
  // Global
  FEATURE_ENABLED: "g/featureEnabled",
  GET_CAMPAIGN_NAME_BY_ID: "g/getCampaignNameById",
  RADIUS_TARGET_BY_ID: "g/radiusTargetById",
  USER_EMAIL_BY_ID: "g/userEmailById",
  USER_NAME_BY_ID: "g/userNameById",
  CONNECT_FILTER_BY_ID: "g/connectFilterById",
  GET_CURRENT_DEPARTMENT: "g/getCurrentDepartment",
  SLATE_QUERY_BY_ID: "g/slateQueryById",
  RE_NXT_LIST_BY_ID: "g/reNxtListById",

  // Inbox
  MESSAGE_COUNT: "g/inbox/messageCount",
  NO_CONVERSATIONS: "g/inbox/noConversations",
  SHARED_INBOX_BY_ID: "g/inbox/sharedInboxById",
  SORTED_CONVERSATIONS: "g/inbox/sortedConversations",
  GET_INBOX_LAST_MESSAGE_TIME_AGO: "g/inbox/lastMessageTimeAgo",

  // Scheduled Messages
  GET_USERS_JSON: "g/scheduled/getUsersJson",
  SCHEDULED_MESSAGES_GROUPED_BY_DATE: "g/scheduled/groupedByDate",

  // Contact
  CONTACT_DISPLAY_NAME: "g/contact/contactDisplayName",
  CONTACT_FIRST_NAME: "g/contact/contactFirstName",

  // Tags
  GET_TAG_NAME_BY_ID: "g/tag/getTagNameById",

  // Web Chat
  GET_WEBCHAT_CONVERSATION_BY_ID: "g/webChat/getConversationById",
  GET_UNREAD_WEBCHAT_MY_CONVERSATIONS_COUNT:
    "g/webChat/getUnreadMyConversationsCount",
  GET_UNREAD_WEBCHAT_UNASSIGNED_CONVERSATIONS_COUNT:
    "g/webChat/getUnreadUnassignedConversationsCount",
  GET_NEXT_WEBCHAT_VISITOR_COLOR: "g/webChat/getNextVisitorColor",
  GET_WEBCHAT_MY_CONVERSATIONS: "g/webChat/getMyConversations",
  GET_WEBCHAT_FOLLOW_UPS: "g/webChat/getFollowUps",
  GET_WEBCHAT_OTHER_CONVERSATIONS: "g/webChat/getOtherConversations",
  GET_WEBCHAT_FILTERED_CONVERSATIONS: "g/webChat/getFilteredConversations",
  GET_WEBCHAT_BOT_BOTPRESSID: "g/webChat/getWebchatBotBotPressId",
  GET_WEBCHAT_LAST_MESSAGE_SELECTED_CONVERSATION: "g/webChat/getLastMessage",
  GET_WEBCHAT_TYPING_TEXT: "g/webChat/getTypingText",
  IS_SELECTED_WEBCHAT_CONVERSATION: "g/webChat/isSelectedConversation",

  //Analytics
  TEAM_NAME_BY_ID: "g/analytics/teamNameById",
  EXPORTS_HIDE_HIGH_SPEED: "g/analytics/exportsHideHighSpeed",
};

export const MUTATIONS = {
  // Global
  SET_CAMPAIGNS: "m/setCampaigns",
  SET_CONFIG: "m/setConfig",
  SET_RADIUS_TARGETS: "m/setRadiusTargets",
  SET_USERS: "m/setUsers",
  UPDATE_CAMPAIGN: "m/updateCampaign",
  UPDATE_CAMPAIGNS: "m/updateCampaigns",
  SET_CONNECT_FILTERS: "m/setConnectFilters",
  SHOW_CONTACT_CARD: "m/showContactCard",
  SET_SLATE_QUERIES: "m/setSlateQueries",
  SET_RE_NXT_LISTS: "m/setReNxtLists",

  // Inbox
  ARCHIVE_CONVERSATION: "m/inbox/archiveConversation",
  BIND_CONVERSATION: "m/inbox/bindConversation",
  BIND_CONVERSATIONS: "m/inbox/bindConversations",
  CONVERSATIONS_LOADED: "m/inbox/conversationsLoaded",
  LOAD_CONVERSATION_QUEUE: "m/inbox/loadConversationQueue",
  MARK_AS_READ_CONVERSATION: "m/inbox/markAsReadConversation",
  PAUSE_LIVE_INBOX: "m/inbox/pauseLiveInbox",
  RESET_CONVERSATION: "m/inbox/resetConversation",
  RESET_CONVERSATIONS: "m/inbox/resetConversations",
  RESET_LOCKED_CONVERSATION: "m/inbox/resetLockedConversation",
  RESUME_LIVE_INBOX: "m/inbox/resumeLiveInbox",
  SET_BULK_ACTIONS_SELECTED: "m/inbox/setBulkActionsSelected",
  SET_CONVERSATION_ID: "m/inbox/setConversationId",
  SET_CONVERSATIONS: "m/inbox/setConversations",
  SET_EMPTY_INBOX_MESSAGES_INDEX: "m/inbox/setEmptyInboxMessagesIndex",
  SET_EMPTY_INBOX_MESSAGES: "m/inbox/setEmptyInboxMessages",
  SET_LOCKED_CONVERSATION: "m/inbox/setLockedConversation",
  SET_NEXT_CONVERSATIONS_LOADING_STATE:
    "m/inbox/setNextConversationsLoadingState",
  SET_NEXT_CONVERSATIONS: "m/inbox/setNextConversations",
  SET_NEXT_PAGE_TOKEN: "m/inbox/setNextPageToken",
  SET_SELECTED_CONVERSATION_MANUALLY_UNREAD:
    "m/inbox/setSelectedConversationManuallyUnread",
  SET_INBOX_SENT: "m/inbox/setSent",
  SET_TOTAL_BATCHES: "m/inbox/setTotalBatches",
  UPDATE_ACTIVE_FILTER: "m/inbox/updateActiveFilter",
  UPDATE_ACTIVE_INBOX_FILTER: "m/inbox/updateActiveInboxFilter",
  UPDATE_ACTIVE_INBOX_SEARCH: "m/inbox/updateActiveInboxSearch",
  UPDATE_ACTIVE_INBOX_SORT: "m/inbox/updateActiveInboxSort",
  UPDATE_ACTIVE_INBOX: "m/inbox/updateActiveInbox",
  UPDATE_CONVERSATION: "m/inbox/updateConversation",
  UPDATE_FIRESTORE_CONVERSATIONS: "m/inbox/updateFirestoreConversations",
  UPDATE_FIRESTORE_SEARCH_RESULT: "m/inbox/updateFirestoreSearchResult",
  UPDATE_INBOX_FILTERS: "m/inbox/updateInboxFilters",
  UPDATE_INBOX_VIEW: "m/inbox/updateInboxView",

  // Scheduled Messages
  RESET_PAGINATION: "m/scheduled/resetPagination",
  SET_CURRENT_PAGE: "m/scheduled/setCurrentPage",
  SET_NEXT_PAGE: "m/scheduled/setNextPage",
  SET_PAGINATION_META: "m/scheduled/setPaginationMeta",
  SET_SCHEDULED_CALENDAR_MONTH: "m/scheduled/setScheduledCalendarMonth",
  SET_SCHEDULED_CALENDAR_YEAR: "m/scheduled/setScheduledCalendarYear",
  SET_SCHEDULED_DAY: "m/scheduled/setScheduledDay",
  SET_SCHEDULED_MESSAGES_COUNTS: "m/scheduled/setCounts",
  SET_SCHEDULED_MESSAGES: "m/scheduled/setMessages",
  SET_SCHEDULED_MONTH: "m/scheduled/setScheduledMonth",
  SET_SCHEDULED_YEAR: "m/scheduled/setScheduledYear",
  SET_SEGMENTS: "m/scheduled/setSegments",
  SET_SELECTED_CALENDAR_DAY: "m/scheduled/setSelectedCalendarDay",
  SET_SELECTED_DAY: "m/scheduled/setSelectedDay",
  SET_SELECTED_INBOX: "m/scheduled/setSelectedInbox",

  // Automation
  SET_AUTOMATION_SETTINGS: "m/automation/setSettings",
  SET_MULTIPLE_AUTOMATION_SETTINGS: "m/automation/setMultipleSettings",

  // Contact
  SET_CONTACT_HISTORY_SKIP: "m/contact/setHistorySkip",
  SET_CONTACT_HISTORY: "m/contact/setHistory",
  SET_CONTACT: "m/contact/setContact",

  // Tags
  SET_TAGS: "m/tag/setTags",
  UPDATE_TAG_DETAIL_FILTER: "m/tag/updateTagDetailFilter",
  UPDATE_TAG_LIST_FILTER: "m/tag/updateTagListFilter",
  UPDATE_TAG: "m/tag/updateTag",

  // Web Chat
  SET_SELECTED_WEBCHAT_CONVERSATION: "m/webChat/setSelectedConversation",
  SET_SELECTED_WEBCHAT_MESSAGES: "m/webChat/setSelectedMessages",
  SET_WEBCHAT_CONVERSATIONS: "m/webChat/setConversations",
  SET_SIGNALR_WEBCHAT_CONNECTION: "m/webChat/setSignalRWebChatConnection",
  SET_WEBCHAT_BOT: "m/webChat/setBot",
  SET_WEBCHAT_UNREAD_MY_CONVERSATIONS_COUNT:
    "m/webChat/setUnreadMyConversationsCount",
  SET_WEBCHAT_UNREAD_UNASSIGNED_CONVERSATIONS_COUNT:
    "m/webChat/setUnreadUnassignedConversationsCount",
  SET_WEBCHAT_VISITOR_AVATAR_COLOR_INDEX:
    "m/webChat/setVisitorAvatarColorIndex",
  SET_WEBCHAT_HEARTBEAT: "m/webChat/setHeartbeat",
  SET_AVAILABLE_TO_CHAT: "m/webChat/setAvailable",
  SET_WEBCHAT_FILTERS: "m/webChat/setFilters",
  SET_SIGNALR_CONNECTION_RETRY: "m/webChat/setSignalRConnectionRetry",
  SET_WEBCHAT_SHOW_MOBILE_CONVERSATION_VIEW:
    "m/webChat/setShowMobileConversationView",
  SET_FOLLOW_UPS_TOTALS: "m/webChat/setFollowUpsTotal",
  SET_OTHER_CONVERSATIONS_TOTALS: "m/webChat/setOtherConversationsTotal",
  SET_WEBCHAT_TYPING_TEXT: "m/webChat/setTypingText",
  SET_WEBCHAT_TYPERS: "m/webChat/setTypers",
  SET_WEBCHAT_INCOMING_TYPING_TIMER: "m/webChat/setIncomingTypingTimer",
  SET_WEBCHAT_OUTGOING_TYPING_TIMER: "m/webChat/setOutgoingTypingTimer",
  SET_WEBCHAT_TEMPLATES: "a/webChat/setTemplates",

  //Analytics
  SET_METRICS: "m/analytics/setMetrics",
  SET_TEAM_ID: "m/analytics/setTeamId",
  SET_CURRENT_TEAMS: "m/analytics/setCurrentTeams",
  SET_CURRENT_INBOXES: "m/analytics/setCurrentInboxes",
  SET_TEAMS_BREAKDOWN: "m/analytics/setTeamsBreakdown",
  SET_USERS_BREAKDOWN: "m/analytics/setUsersBreakdown",
  SET_CAMPAIGNS_BREAKDOWN: "m/analytics/setCampaignsBreakdown",
  SET_INBOXES_BREAKDOWN: "m/analytics/setInboxesBreakdown",
  SET_ANALYTICS_DATES: "m/analytics/setAnalyticsDates",
  SET_BREAKDOWN_TYPE: "m/analytics/setBreakdownType",
  SET_ANALYTICS_FILTER: "m/analytics/setAnalyticsFilter",
  SET_ANALYTICS_INTERVAL: "m/analytics/setAnalyticsInterval",
  SET_BREAKDOWN_SKIP: "m/analytics/setBreakdownSkip",
  RESET_BREAKDOWN_SKIP: "m/analytics/resetBreakdownSkip",
  SET_SORT: "m/analytics/setSort",
  SET_SEARCH: "m/analytics/setSearch",
  SET_INBOX_DETAIL_SEARCH: "m/analytics/setInboxDetailSearch",
  SET_INBOX_DETAILS_METRICS: "m/analytics/setInboxDetailsMetrics",
  SET_INBOX_DETAILS_BREAKDOWN: "m/analytics/setInboxDetailsBreakdown",
  SET_INBOX_DETAIL_SKIP: "m/analytics/setInboxDetailSkip",
  RESET_INBOX_DETAIL_SKIP: "m/analytics/resetInboxDetailSkip",
  SET_INBOX_DETAILS_CONTEXT: "m/analytics/setInboxDetailsContext",

  //Shared Inbox
  SET_SHARED_INBOXES: "m/sharedInbox/setSharedInboxes",
  SET_SHARED_INBOX_NUMBERS: "m/sharedInbox/setSharedInboxNumbers",
  SET_SHARED_INBOX_RESERVED_NUMBERS:
    "m/sharedInbox/setSharedInboxReservedNumbers",
  SET_SHARED_INBOX_STAFF: "m/sharedInbox/setSharedInboxStaff",
  SET_SHARED_INBOX_SETTINGS: "m/sharedInbox/setSharedInboxSettings",
  SET_CONTRACT: "m/sharedInbox/setContract",
  CLEAR_SHARED_INBOX_AVAILABLE_NUMBERS:
    "m/sharedInbox/clearSharedInboxAvailableNumbers",
  SET_LOOKUP_NUMBER: "m/sharedInbox/setLookupNumber",
};

export const COMPARISONS = {
  EQUALS: "equals",
  NOT_EQUALS: "notequals",
  STARTS_WITH: "startswith",
  IS_LESS_THAN: "islessthan",
  IS_GREATER_THAN: "isgreaterthan",
  IS_EMPTY: "isempty",
  IS_NOT_EMPTY: "isnotempty",
  WITH: "with",
  WITHOUT: "without",
};

export const CONVERSATION_FILTERS = {
  ALL: "all",
  READ: "read",
  UNREAD: "unread",
};

export const HELP = {
  contact: {
    optOutVsBlock:
      "Opt-out prevents a contact from receiving messages from this team. Block is primarily used to deal with spam or inappropriate messages. Messages cannot be sent to or received from a blocked contact.",
    messageRetention: "Message history is retained for a period of 2 years.",
  },
};

export const INBOX_TYPES = {
  PERSONAL: "personal",
  GROUP: "group",
  HIGH_SPEED: "high-speed",
};

export const INBOX_TYPE_SOURCES = {
  personal: "firestore",
  group: "firestore",
  "high-speed": "signalR",
};

export const INBOX_SOURCES = {
  FIRESTORE: "firestore",
  SIGNALR: "signalR",
};

export const MESSAGE_DIRECTIONS = {
  INCOMING: "incoming",
  OUTGOING: "outgoing",
};

export const MESSAGE_SOURCES = {
  API: "Api",
  COMPOSE: "Compose",
  CONVERSATION: "Conversation",
  SMART_MESSAGE_AUTO_REPLY: "SmartMessageAutoReply",
};

export const EMPTY_INBOX_MESSAGES = [
  {
    text: "Enjoy this ten-second, zen moment of empty-inbox serenity.",
    icon: "🧘",
    animation: "fadeIn",
  },
  {
    text: "Everyone is talking about how clean your inbox is. Great work!",
    icon: "✨",
    animation: "zoomInDown",
  },
  {
    text: "Boom! Inbox is empty. Fist bump.",
    icon: "🤜",
    animation: "slideInLeft",
  },
  {
    text:
      "Now that your inbox is empty, do you kind of miss all those messages?",
    icon: "🥹",
    animation: "fadeIn",
  },
  {
    text: "Empty inbox? Totally rad! <br>Do kids still say “rad?”",
    icon: "🏄",
    animation: "lightSpeedIn",
  },
  {
    text: "They say an empty inbox is a sign of mental superiority.",
    icon: "🤯",
    animation: "fadeInUp",
  },
  {
    text: "Nothing like a clean inbox.<br/>Nice job, super&nbsp;star.",
    icon: "⭐",
    animation: "zoomInDown",
  },
  {
    text:
      "<b><i>BOOM</i></b>. No more messages.<br/>Give yourself a pat on the back.<br/> Hug a stranger.<br/>Whatever you’re into.",
    icon: "👍",
    animation: "jackInTheBox",
  },
  {
    text: "Nothing in your inbox.",
    icon: "🙌",
    animation: "fadeInUp",
  },
  {
    text: "Leave no message unread?<br />Check. Nice&nbsp;work!",
    icon: "🎉",
    animation: "tada",
  },
  {
    text:
      "Has anyone ever told you how good you are at reading incoming&nbsp;messages?",
    icon: "🤩",
    animation: "fadeInUp",
  },
  {
    text: "Something tells me you’re<br/>good at your&nbsp;job.",
    icon: "🍎",
    animation: "fadeIn",
  },
];

export const RESERVED_KEYWORDS = [
  "STOP",
  "CANCEL",
  "END",
  "QUIT",
  "UNSUBSCRIBE",
  "HELP",
  "INFO",
];

export const MESSAGE_TYPES = {
  STANDARD: "Standard",
  QUESTION: "Question",
  AUTO_REPLY: "AutoReply",
  OUT_OF_OFFICE: "OutOfOffice",
  KEYWORD_AUTO_REPLY: "KeywordAutoReply",
};

export const HISTORY_MESSAGE_TYPES = {
  STANDARD: 1,
  QUESTION: 2,
  AUTO_REPLY: 3,
  OUT_OF_OFFICE: 4,
  KEYWORD_AUTO_REPLY: 5,
};

export const API_ERROR_MESSAGES = {
  DUPLICATE_TAG: "Tag name already exists in department.",
};

export const WEBCHAT_NOTIFICATION_TYPES = {
  CONVERSATION_CREATED: "ConversationCreated",
  CONVERSATION_DELETED: "ConversationDeleted",
  CONVERSATION_ENDED: "ConversationEnded",
  CHAT_REQUEST: "ChatRequest",
  MESSAGE_RECEIVED: "MessageReceived",
  MESSAGE_SENT: "MessageSent",
  CONVERSATION_TIMEOUT: "ConversationTimeout",
  VISITOR_DATA_CAPTURED: "VisitorDataCaptured",
  CONVERSATION_REOPENED: "ConversationReopened",
  CONVERSATION_NEEDS_FOLLOWUP: "ConversationNeedsFollowup",
  CONVERSATION_FOLLOWUP_COMPLETE: "ConversationCompleteFollowup",
  CONVERSATION_UNASSIGNED: "ConversationUnassigned",
  VISITOR_TYPING: "VisitorTyping",
  AGENT_TYPING: "AgentTyping",
  MANUAL_ASSIGNMENT: "ManualAssignment",
  CONVERSATION_TRANSFERRED: "ConversationTransferred",
  CONVERSATION_READ: "ConversationRead",
};

export const WEBCHAT_VISITOR_AVATAR_COLORS = [
  "avatar-desert",
  "avatar-seafoam",
  "avatar-cornwall",
  "avatar-lilac",
  "avatar-mulberry",
  "avatar-ochre",
  "avatar-blonde",
  "avatar-sage",
];

export const WEBCHAT_USER_CUSTOMFIELD_DENYLIST = ["timezone", "language"];

export const WEBCHAT_FILTERS = {
  USER_ASSISTED: "userAssisted",
  BOT_ONLY: "botOnly",
  CONVERSATION_OPEN: "conversationOpen",
  CONVERSATION_CLOSED: "conversationClosed",
  CONVERSATION_READ: "conversationRead",
  CONVERSATION_UNREAD: "conversationUnread",
  ALL: "",
};

export const WEBCHAT_PAGINATION = {
  TAKE: 20,
};

export const DEFAULT_CHAT_AVATAR_URLS = [
  "https://mongoose.blob.core.windows.net/chat-widget/default-avatar.png",
  "https://mongoose-stor-cdn.hellomongoose.com/chat-widget/default-avatar.png",
];

export const US_REGION_LIST = [
  {
    regionCode: "AK",
    regionName: "Alaska",
  },
  {
    regionCode: "AL",
    regionName: "Alabama",
  },
  {
    regionCode: "AR",
    regionName: "Arkansas",
  },
  {
    regionCode: "AZ",
    regionName: "Arizona",
  },
  {
    regionCode: "CA",
    regionName: "California",
  },
  {
    regionCode: "CO",
    regionName: "Colorado",
  },
  {
    regionCode: "CT",
    regionName: "Connecticut",
  },
  {
    regionCode: "DC",
    regionName: "Washington, D.C.",
  },
  {
    regionCode: "DE",
    regionName: "Delaware",
  },
  {
    regionCode: "FL",
    regionName: "Florida",
  },
  {
    regionCode: "GA",
    regionName: "Georgia",
  },
  {
    regionCode: "HI",
    regionName: "Hawaii",
  },
  {
    regionCode: "IA",
    regionName: "Iowa",
  },
  {
    regionCode: "ID",
    regionName: "Idaho",
  },
  {
    regionCode: "IL",
    regionName: "Illinois",
  },
  {
    regionCode: "IN",
    regionName: "Indiana",
  },
  {
    regionCode: "KS",
    regionName: "Kansas",
  },
  {
    regionCode: "KY",
    regionName: "Kentucky",
  },
  {
    regionCode: "LA",
    regionName: "Louisiana",
  },
  {
    regionCode: "MA",
    regionName: "Massachusetts",
  },
  {
    regionCode: "MD",
    regionName: "Maryland",
  },
  {
    regionCode: "ME",
    regionName: "Maine",
  },
  {
    regionCode: "MI",
    regionName: "Michigan",
  },
  {
    regionCode: "MN",
    regionName: "Minnesota",
  },
  {
    regionCode: "MO",
    regionName: "Missouri",
  },
  {
    regionCode: "MS",
    regionName: "Mississippi",
  },
  {
    regionCode: "MT",
    regionName: "Montana",
  },
  {
    regionCode: "NC",
    regionName: "North Carolina",
  },
  {
    regionCode: "ND",
    regionName: "North Dakota",
  },
  {
    regionCode: "NE",
    regionName: "Nebraska",
  },
  {
    regionCode: "NH",
    regionName: "New Hampshire",
  },
  {
    regionCode: "NJ",
    regionName: "New Jersey",
  },
  {
    regionCode: "NM",
    regionName: "New Mexico",
  },
  {
    regionCode: "NV",
    regionName: "Nevada",
  },
  {
    regionCode: "NY",
    regionName: "New York",
  },
  {
    regionCode: "OH",
    regionName: "Ohio",
  },
  {
    regionCode: "OK",
    regionName: "Oklahoma",
  },
  {
    regionCode: "OR",
    regionName: "Oregon",
  },
  {
    regionCode: "PA",
    regionName: "Pennsylvania",
  },
  {
    regionCode: "RI",
    regionName: "Rhode Island",
  },
  {
    regionCode: "SC",
    regionName: "South Carolina",
  },
  {
    regionCode: "SD",
    regionName: "South Dakota",
  },
  {
    regionCode: "TN",
    regionName: "Tennessee",
  },
  {
    regionCode: "TX",
    regionName: "Texas",
  },
  {
    regionCode: "UT",
    regionName: "Utah",
  },
  {
    regionCode: "VA",
    regionName: "Virginia",
  },
  {
    regionCode: "VT",
    regionName: "Vermont",
  },
  {
    regionCode: "WA",
    regionName: "Washington",
  },
  {
    regionCode: "WI",
    regionName: "Wisconsin",
  },
  {
    regionCode: "WV",
    regionName: "West Virginia",
  },
  {
    regionCode: "WY",
    regionName: "Wyoming",
  },
];
