<template>
  <VueCalendar
    ref="calendar"
    :masks="masks"
    class="scheduled-messages-calendar"
    :theme="themeStyles"
  >
    <template slot="header" slot-scope="props">
      <div class="header-container">
        <div
          class="month-navigation mb-3 d-flex justify-content-between align-items-center"
        >
          <div class="current-month" v-html="props.title"></div>
          <div class="d-flex align-items-center">
            <div class="change-wrapper mr-4 d-flex justify-content-between">
              <button
                type="button"
                class="btn"
                v-on:click="changeMonth(-1, props)"
              >
                <span class="sr-only">Previous Month</span>
                <MgIcon name="chevron-left"></MgIcon>
              </button>
              <button
                type="button"
                class="btn"
                v-on:click="changeMonth(1, props)"
              >
                <span class="sr-only">Next Month</span>
                <MgIcon name="chevron-right"></MgIcon>
              </button>
            </div>
            <BButton class="btn btn-light text-dark" v-on:click="getToday()"
              >Today</BButton
            >
          </div>
        </div>
        <BButtonGroup class="inbox-filter mb-2 d-flex">
          <BButton
            v-on:click="selectInbox({ type: 'team', name: 'Team' })"
            :class="{ isSelectedInbox: selectedFilter === 'team' }"
            class="filter-button flex-fill"
            >Team</BButton
          >
          <template>
            <b-button
              v-on:click="selectInbox({ type: 'mine', name: 'Mine' })"
              :class="{ isSelectedInbox: selectedFilter === 'mine' }"
              class="filter-button flex-fill"
              >Mine</b-button
            >
          </template>
          <BDropdown
            class="filter-button flex-fill"
            :class="{ dropDownSelected: selectedFilter === 'sharedInbox' }"
            right
            text="Shared Inbox"
            v-if="sharedInboxes.length"
          >
            <template v-for="inbox in sharedInboxes">
              <BDropdownItem
                v-on:click="selectInbox(inbox)"
                :key="inbox.id"
                :class="{
                  isSelectedInbox:
                    selectedFilter === 'sharedInbox' &&
                    inbox.name === selectedInbox.name,
                }"
                >{{ inbox.name }}</BDropdownItem
              >
            </template>
          </BDropdown>
        </BButtonGroup>
      </div>
    </template>
    <template slot="day-content" slot-scope="props">
      <div
        :class="{
          isToday: props.day.isToday,
          isPast: today > Date.parse(props.day.date) && !props.day.isToday,
          isSelectedDay: props.day.date - selectedDay === 0,
        }"
        class="day-cell d-flex flex-column align-items-center"
        v-on:click="selectDay(props.day.date)"
      >
        <span
          class="day d-flex text-align-center justify-content-center align-items-center"
          >{{ props.day.day }}</span
        >
        <div
          v-if="getCount(props.day.date)"
          v-html="getCount(props.day.date)"
        ></div>
      </div>
    </template>
  </VueCalendar>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import { ACTIONS, INBOX_TYPES } from "@/util/constants";
import MgIcon from "../MgIcon";
import analytics from "@/util/analytics";
import VueCalendar from "v-calendar/lib/components/calendar.umd";
import {
  BVConfigPlugin,
  BDropdown,
  BDropdownItem,
  BButton,
  BButtonGroup,
} from "bootstrap-vue";

Vue.use(BVConfigPlugin, {
  BButton: { variant: "" },
});

Vue.component("VueCalendar", VueCalendar);
Vue.component("BDropdown", BDropdown);
Vue.component("BDropdownItem", BDropdownItem);
Vue.component("BButton", BButton);
Vue.component("BButtonGroup", BButtonGroup);

export default {
  name: "DatePicker",
  store,
  components: {
    VueCalendar,
    MgIcon,
  },
  data() {
    return {
      themeStyles: {
        dayNotInMonth: "not-in-month",
      },
      masks: {
        weekdays: "WWW",
      },
      today: new Date(new Date().setHours(0, 0, 0, 0)),
      selectedDay: new Date(new Date().setHours(0, 0, 0, 0)),
      selectedFilter: "team",
    };
  },
  created() {
    this.initialLoad();
  },
  watch: {},
  props: {
    inboxes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    calendarMonth() {
      return this.$store.state.scheduled.calendarMonth;
    },
    calendarYear() {
      return this.$store.state.scheduled.calendarYear;
    },
    scheduledMessageCount() {
      return this.$store.state.scheduled.counts;
    },
    selectedCalendarDate() {
      return this.$store.state.scheduled.selectedCalendarDate;
    },
    selectedDate() {
      return this.$store.state.scheduled.selectedDate;
    },
    selectedInbox() {
      return this.$store.state.scheduled.selectedInbox;
    },
    sharedInboxes() {
      return this.inboxes.filter((inbox) => inbox.type === INBOX_TYPES.GROUP);
    },
  },
  methods: {
    getCount(date) {
      let count;
      let parsedDate = Date.parse(date);
      this.scheduledMessageCount.forEach(function (d) {
        let parsedCountDate = Date.parse(d.date);
        if (parsedCountDate === parsedDate) {
          count = d.count;
        }
      });
      if (count) {
        return '<div class="count d-flex"><span>' + count + "</span></div>";
      }
    },
    changeMonth(value) {
      if (this.calendarMonth === 1 && value === -1) {
        this.$store.dispatch(
          ACTIONS.UPDATE_SCHEDULED_CALENDAR_YEAR,
          this.calendarYear - 1
        );
        this.$store.dispatch(ACTIONS.UPDATE_SCHEDULED_CALENDAR_MONTH, 12);
      } else if (this.calendarMonth === 12 && value === 1) {
        this.$store.dispatch(
          ACTIONS.UPDATE_SCHEDULED_CALENDAR_YEAR,
          this.calendarYear + 1
        );
        this.$store.dispatch(ACTIONS.UPDATE_SCHEDULED_CALENDAR_MONTH, 1);
      } else if (this.calendarMonth !== 1 || this.calendarMonth !== 12) {
        this.$store.dispatch(
          ACTIONS.UPDATE_SCHEDULED_CALENDAR_MONTH,
          this.calendarMonth + value
        );
      }

      this.$refs.calendar.move(value);

      this.$store.dispatch(ACTIONS.UPDATE_SELECTED_CALENDAR_DAY);
      this.$store.dispatch(
        ACTIONS.GET_SCHEDULED_MESSAGES_COUNTS,
        this.selectedCalendarDate
      );
    },
    getDate(date) {
      this.$store.dispatch(ACTIONS.UPDATE_SCHEDULED_DAY, date.getDate());
      this.$store.dispatch(ACTIONS.UPDATE_SCHEDULED_MONTH, date.getMonth() + 1);
      this.$store.dispatch(ACTIONS.UPDATE_SCHEDULED_YEAR, date.getFullYear());
      this.$store.dispatch(
        ACTIONS.UPDATE_SCHEDULED_CALENDAR_MONTH,
        date.getMonth() + 1
      );
      this.$store.dispatch(
        ACTIONS.UPDATE_SCHEDULED_CALENDAR_YEAR,
        date.getFullYear()
      );
    },
    getToday() {
      this.initialLoad();
      this.$refs.calendar.move(new Date());
      this.scrollToTop();
    },
    initialLoad() {
      this.$store.dispatch(ACTIONS.UPDATE_SELECTED_INBOX, this.selectedInbox);
      if (this.selectedInbox.type === "mine") {
        this.selectedFilter = "mine";
      } else if (this.selectedInbox.type === "group") {
        this.selectedFilter = "sharedInbox";
      } else {
        this.selectedFilter = "team";
      }
      this.selectDay(this.today);
    },
    scrollToTop() {
      let scheduledMessagesList = document.querySelector(
        ".scheduled-messages-list"
      );
      if (scheduledMessagesList) {
        setTimeout(function () {
          scheduledMessagesList.scrollIntoView(true);
        }, 500);
      }
    },
    selectDay(date) {
      this.selectedDay = date;
      this.getDate(date);

      this.$store.dispatch(ACTIONS.UPDATE_SELECTED_DAY);
      this.$store.dispatch(ACTIONS.UPDATE_SELECTED_CALENDAR_DAY);
      this.$store.dispatch(ACTIONS.GET_SCHEDULED_MESSAGES, this.selectedDate);
      this.$store.dispatch(
        ACTIONS.GET_SCHEDULED_MESSAGES_COUNTS,
        this.selectedDate
      );

      this.scrollToTop();
    },
    selectInbox(inbox) {
      if (inbox.type === "team") {
        this.selectedFilter = "team";
      } else if (inbox.type === "mine") {
        this.selectedFilter = "mine";
      } else {
        this.selectedFilter = "sharedInbox";
      }
      this.$store.dispatch(ACTIONS.UPDATE_SELECTED_INBOX, inbox);
      this.$store.dispatch(ACTIONS.GET_SCHEDULED_MESSAGES, this.selectedDate);
      this.$store.dispatch(
        ACTIONS.GET_SCHEDULED_MESSAGES_COUNTS,
        this.selectedCalendarDate
      );
      analytics.track("User Filtered Scheduled Messages", {
        "Inbox Type": inbox.name,
      });

      this.scrollToTop();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.scheduled-messages-calendar {
  border: none !important;
  font-family: $font-family-sans-serif;
  position: sticky;
  top: 0;
  width: 100%;
  min-width: 260px;
  max-width: 500px;
  .change-wrapper {
    width: 50px;
    @include media-breakpoint-up(xl) {
      width: 60px;
    }
    button {
      @include media-breakpoint-up(lg) {
        font-size: 12px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 14px;
      }
    }
  }
  .change-icon {
    .icon {
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
  .header-container {
    padding-bottom: 10px;

    .month-navigation {
      .current-month {
        font-size: 20px;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
        @include media-breakpoint-down(md) {
          font-size: 20px;
        }
      }
      .icon {
        cursor: pointer;
      }
    }

    .inbox-filter {
      background-color: #f9f9f9;
      padding: 0;
      font-size: $font-size-base;
      height: 32px;
      button {
        padding: 0.25rem 0.5rem;
        font-size: 0.8rem;
        line-height: 1.5;
        background-color: #f9f9f9;
        border-color: #d9d9d9;
        border-radius: 0.2rem;
        color: $gray-900;
        &:not(:last-child):not(:first-child) {
          border-radius: 0;
        }
        &:hover {
          background-color: #e0e0e0;
        }
      }
      .filter-button {
        &.dropdown {
          width: 105px;
          @include media-breakpoint-up(xl) {
            width: 80px;
          }
        }
        ::v-deep .dropdown-toggle {
          padding: 0;
          background-color: #f9f9f9;
          color: $gray-900;
          border-color: #d9d9d9;
          font-size: 0.8rem;
          &:hover {
            background-color: #e0e0e0;
          }
        }
      }
      .dropdown-item {
        &:hover {
          background-color: #e0e0e0;
        }
      }
      .dropDownSelected {
        ::v-deep .dropdown-toggle {
          background-color: #e0e0e0;
        }
      }
      .isSelectedInbox {
        background-color: #e0e0e0;
        border-color: #d9d9d9;
        .dropdown-item {
          background-color: #e0e0e0;
          border-color: #d9d9d9;
          border-radius: 0;
        }
      }
    }
  }

  .day-cell {
    cursor: pointer;
    min-height: 65px;
    ::v-deep .count {
      border-radius: 50%;
      color: $link-color;
      display: flex;
      font-weight: $font-weight-bold;
      width: 100%;
      height: 100%;
      text-align: center;
    }
    .day {
      background-color: #fff;
      color: $gray-700;
      border-radius: 50%;
      height: 34px;
      width: 34px;
      font-size: 16px;
      transition: background-color 250ms ease;
    }
    &:focus,
    &:hover {
      .day {
        background-color: $gray-200;
        color: $gray-800;
      }
    }
    &.isSelectedDay {
      .day {
        background-color: $gray-300;
        color: $gray-800;
      }
    }
    &.isToday {
      .day {
        color: white;
        background-color: $blue !important;
      }
    }
  }

  .not-in-month {
    .day {
      color: $gray-500;
    }
  }

  ::v-deep .vc-arrows-container {
    display: none;
  }

  ::v-deep .vc-weeks {
    border: 1px solid #f2f2f2;
    -webkit-box-shadow: 3px 3px 2px 0px rgba(242, 242, 242, 1);
    -moz-box-shadow: 3px 3px 2px 0px rgba(242, 242, 242, 1);
    box-shadow: 3px 3px 2px 0px rgba(242, 242, 242, 1);
    border-radius: 5px;
  }

  ::v-deep .vc-weekday {
    text-transform: uppercase;
    color: #333;
    font-weight: $font-weight-bold;
  }

  ::v-deep .vc-pane {
    padding: 0 5px 5px 0;
  }
}
</style>
