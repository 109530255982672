<template>
  <div @keyup.enter="handleEnterPress">
    <MgKendoCard orientation="horizontal" hideHeader hideFooter tabindex="0">
      <template v-slot:body>
        <div class="d-flex flex-row justify-content-start">
          <span class="chevron" :class="{ 'chevron-expanded': expanded }"
            ><MgIcon name="chevron-right"
          /></span>
          <span class="title mr-auto">{{ title }}</span>
          <BBadge
            v-if="numberOfConversations"
            class="d-flex align-items-center"
            variant="danger"
            data-testid="number-badge"
            >{{ numberOfConversations }}</BBadge
          >
        </div>
      </template>
    </MgKendoCard>
  </div>
</template>
<script>
import MgKendoCard from "@/components/MgKendoCard";
import MgIcon from "@/components/MgIcon";
import { BBadge } from "bootstrap-vue";

export default {
  name: "WebChatConversationListGroupHeader",
  components: { MgKendoCard, MgIcon, BBadge },
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    numberOfConversations: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    handleEnterPress() {
      this.$emit("change-collapse-state", { expanded: !this.expanded });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.title {
  margin-left: 26px;
}

.badge {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: medium;

  &.badge-danger {
    background-color: $red-danger;
  }
}

.k-card {
  border-radius: 0;
  border-right: none;
  border-left: none;
  border-bottom: none;
  border-color: $gray-100;
  background-color: $gray-lighter;
  font-weight: $font-weight-normal;
  font-size: 15px;
}

.chevron {
  position: absolute;
  @include transition(transform 300ms);

  &.chevron-expanded {
    transform: rotate(90deg);
  }
}

::v-deep .k-card-body {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}
</style>
