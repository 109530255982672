<template>
  <div
    class="d-flex flex-column justify-content-between scheduled-messages-list w-100"
  >
    <div v-if="!isEmpty(scheduledMessages)">
      <div
        class="d-flex flex-column"
        v-for="(group, date, index) in scheduledMessages"
        :key="index"
      >
        <h2 class="date-divider text-large pb-3">{{ dateDivider(date) }}</h2>
        <ScheduledMessagesCard
          v-for="message in group"
          :key="message.id"
          :scheduledMessage="message"
          v-on="$listeners"
          v-on:unschedule-message="unscheduleMessage"
          :inboxes="inboxes"
        />
      </div>
    </div>
    <div class="empty-state" v-if="isEmpty(scheduledMessages)">
      <span v-if="selectedInbox.type !== 'team'">{{ selectedInbox.name }}</span>
      <span v-else>{{ teamName }}</span>
      has no scheduled messages on or after {{ selectedDate.month }}/{{
        selectedDate.day
      }}.
    </div>
    <div
      v-if="totalPages > 1"
      class="pagination d-flex align-items-center justify-content-between"
    >
      <button
        type="button"
        class="btn btn-light btn-icon-left"
        :disabled="currentPage === 1"
        v-on:click="skipPage(currentPage - 1)"
      >
        <MgIcon name="left-arrow"></MgIcon>Prev
      </button>
      <div class="text-muted text-small">
        {{ currentPage }} of {{ totalPages }}
      </div>
      <button
        type="button"
        class="btn btn-light btn-icon-right"
        v-on:click="skipPage(currentPage + 1)"
        :disabled="currentPage === totalPages"
      >
        Next<MgIcon name="right-arrow"></MgIcon>
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import moment from "moment";
import ScheduledMessagesCard from "@/components/ScheduledMessagesCard";
import { ACTIONS } from "@/util/constants";
import MgIcon from "../MgIcon";
import _ from "lodash";
import { ToastPlugin } from "bootstrap-vue";
import Toast from "@/components/Toast";
import eventBus from "@/util/eventBus";

Vue.use(ToastPlugin);

export default {
  name: "ScheduledMessagesList",
  store,
  components: {
    ScheduledMessagesCard,
    MgIcon,
  },
  props: {
    scheduledMessages: {
      type: Object,
      required: true,
    },
    inboxes: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.$store.dispatch(ACTIONS.GET_CAMPAIGNS);
    this.$store.dispatch(ACTIONS.GET_SEGMENTS);
    eventBus.addEventListener(
      "updateScheduledMessage",
      this.updateScheduledMessage
    );
  },
  computed: {
    currentPage() {
      return this.$store.state.scheduled.currentPage;
    },
    currentCounts() {
      return this.$store.state.scheduled.selectedDate.count;
    },
    take() {
      return this.$store.state.scheduled.take;
    },
    totalPages() {
      return Math.ceil(
        this.$store.state.scheduled.paginationMeta.total /
          this.$store.state.scheduled.paginationMeta.take
      );
    },
    selectedInbox() {
      return this.$store.state.scheduled.selectedInbox;
    },
    selectedCalendarDate() {
      return this.$store.state.scheduled.selectedCalendarDate;
    },
    selectedDate() {
      return this.$store.state.scheduled.selectedDate;
    },
    teamName() {
      if (this.$store.state.config.team) {
        return this.$store.state.config.team.name;
      } else {
        return "";
      }
    },
  },
  methods: {
    dateDivider(date) {
      return moment(date).format("dddd, MMMM D");
    },
    isEmpty(messages) {
      return _.isEmpty(messages);
    },
    scrollToTop() {
      document.querySelector(".scheduled-messages-list").scrollIntoView();
    },
    skipPage(nextPage) {
      this.$store.dispatch(ACTIONS.SKIP_PAGE, nextPage);
      this.scrollToTop();
    },
    unscheduleMessage(status, message) {
      const toastBody = this.$createElement(Toast, { props: { message } });

      this.$bvToast.toast([toastBody], {
        toastClass: status + "-toast",
        variant: status,
        noCloseButton: true,
        solid: true,
      });
    },
    updateScheduledMessage() {
      this.$store.dispatch(ACTIONS.GET_SCHEDULED_MESSAGES, this.selectedDate);
      this.$store.dispatch(
        ACTIONS.GET_SCHEDULED_MESSAGES_COUNTS,
        this.selectedCalendarDate
      );
    },
  },
};
</script>

<style lang="scss">
.b-toaster.b-toaster-top-right {
  margin-top: 55px !important;
}
</style>

<style lang="scss" scoped>
@import "../../scss/utilities";
.date-divider {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}
.empty-state {
  color: #6c757d;
  font-size: 20px;
  font-weight: 300;
  overflow: hidden;
  padding: 30px;
  text-align: center;
  em {
    font-size: 18px;
  }
}
.pagination {
  background: rgba(255, 255, 255, 0.9);
  bottom: 0;
  margin: 0 -5px;
  padding: 10px 5px;
  position: sticky;
  z-index: 2;
  @include media-breakpoint-up(lg) {
    padding: 10px 5px 10px 85px;
  }

  .btn-icon-left .icon {
    margin-left: -6px;
  }

  .btn-icon-right .icon {
    margin-right: -6px;
  }
}
</style>
